<ng-template #tree let-chapter>
  <div
    class="chapter"
    [class.has-children]="chapter.subchapters.length"
    [class.active]="false"
    [class.selected]="targetChapter && chapter._id === targetChapter._id"
    [class.open]="isChapterOpen(chapter)"
    [class.forbidden]="isChapterForbidden(chapter)"
  >
    <div class="toggle" (click)="toggleChapter(chapter)">
      <i *ngIf="chapter.subchapters.length" class="fas fa-angle-down"></i>
    </div>

    <div class="name" [class.name__has-priority-icon]="chapter.priority !== chapterPriority.normal">
      <div
        (click)="selectChapter(chapter, column);"
        [innerHtml]="(chapter.fullTitle | renderMath)"
      ></div>

      <img
        *ngIf="chapter.priority !== chapterPriority.normal"
        src="assets/images/icons/high-priopity.svg"
        alt="high-priority"
        class="chapter__priority-icon"
      />
    </div>

    <div *ngIf="chapter.subchapters.length" class="children" #chapterChildren>
      <ng-template ngFor [ngForOf]="chapter.subchapters" [ngForTemplate]="tree">
      </ng-template>
    </div>

    <div class="forbidden-popup">
      <ng-container *ngIf="isChapterInMembersPreviewOnly(chapter)">
        <span [innerHTML]="'app.user.register-to-view' | translate"></span>
      </ng-container>
      <ng-container *ngIf="!isChapterInMembersPreviewOnly(chapter)">
        <span [innerHTML]="'app.user.buy-to-view' | translate"></span>
      </ng-container>
    </div>
  </div>
</ng-template>

<div class="backdrop" (click)="open = false" [class.open]="open"></div>

<div
  class="sidebar"
  [attr.data-test-id]="TEST_IDS.ROOT"
  [class.stuck]="!(course || column)"
  [class.open]="open"
  [class.with-course]="course"
  [class.legacy-browser-support]="isSafari"
>
  <div id="topbar">
    <div class="content">
      <div class="toggle" (click)="open = !open">
        <i *ngIf="!open" class="fas fa-bars"></i>
        <i *ngIf="open" class="fas fa-times"></i>
      </div>
      <div class="logo" (click)="open = false" routerLink="/mycourses">
        <img [src]="logoUrl" />
      </div>
    </div>
  </div>

  <div class="content">
    <div
      class="logo"
      *ngIf="!open"
      (click)="open = false"
      routerLink="/mycourses"
    >
      <img [src]="logoUrl" />
    </div>

    <div class="close-app">
      <a routerLink="/home"
        ><i class="fas fa-angle-left"></i> {{'app.sidebar.back-to-landing-page' | translate}}</a
      >
    </div>

    <div class="contact contact_org">
      <h3 class="h3__black-title">{{'app.sidebar.orga-questions-link-label' | translate}}</h3>
      <a
        *ngIf="contactWhatsapp"
        class="link"
        [href]="getOrgWhatsAppLink()"
        target="_blank"
      >
        <img class="icon" src="./assets/studyprime/svg/whatsapp.svg" />
        {{ orgContactWhatsapp }}
      </a>
      <a class="link" [href]="getOrgEmailLink()">
        <i class="icon fas fa-envelope"></i>
        {{ orgContactEmail }}
      </a>
      <p class="contact__tooltip" *ngIf="tooltipShown" [innerHTML]="'app.sidebar.contact-tooltip' | translate">
        Für fachliche Fragen gibt es jeweils eine eigene Nummer, die auf der
        linken Seite im jeweiligen Kurs steht.
      </p>
    </div>

    <div class="contact contact_technical" *ngIf="technicalContactsVisible">
      <h3 class="h3__black-title">{{ 'app.sidebar.content-questions-link-label' | translate }}</h3>
      <a
        *ngIf="contactWhatsapp"
        class="link"
        [href]="getWhatsAppLink()"
        target="_blank"
      >
        <img class="icon" src="./assets/studyprime/svg/whatsapp.svg" />
        {{ contactWhatsapp }}
      </a>
      <a class="link" [href]="getEmailLink()">
        <i class="icon fas fa-envelope"></i>
        {{ contactEmail }}
      </a>
      <p class="contact__tooltip" *ngIf="tooltipShown" [innerHTML]="'app.sidebar.contact-tooltip' | translate">
        Für fachliche Fragen gibt es jeweils eine eigene Nummer, die auf der
        linken Seite im jeweiligen Kurs steht.
      </p>
    </div>

    <div class="course section-divider" *ngIf="course">
      <h3
        [innerHtml]="((course.title + ':') | renderMath)"
        routerLink="/courses/course/{{course.url_slug}}"
        (click)="open = false"
        class="h3__black-title"
      ></h3>
      <div class="links">
        <a
          *ngFor="let col of course.columns"
          class="link"
          [innerHtml]="(col.title | renderMath)"
          (click)="columnClick(col); open = false"
        >
        </a>
      </div>

      <div
        class="learning-overview-link"
        [class.forbidden]="isLearningOverviewForbidden()"
        *ngIf="!column || column?.progress_tracking"
      >
        <div
          class="link drop-shadow-hover"
          (click)="openLearningOverview(); open = false"
        >
          {{ 'app.common.learning-overview' | translate }}
        </div>

        <div class="forbidden-popup">
          <ng-container *ngIf="(loginService.isAnonymous | async)">
            <div style="text-align: center" [innerHTML]="'app.user.register-to-view' | translate">
              Registriere dich bitte, um diese Funktion nutzen zu können<br />
              (weiterhin kostenlos)
            </div>
          </ng-container>
          <ng-container *ngIf="!(loginService.isAnonymous | async)">
            {{ 'app.user.no-progress-info' | translate }}
          </ng-container>
        </div>
      </div>

      <div
        class="index section-divider"
        *ngIf="column && !isLearningOverview"
        #index
      >
        <h3
          [innerHtml]="((column.title + ':') | renderMath)"
          class="h3__black-title"
        ></h3>
        <ng-template
          *ngFor="let chapter of column.chapters"
          [ngTemplateOutlet]="tree"
          [ngTemplateOutletContext]="{ $implicit: chapter }"
        >
        </ng-template>
      </div>

      <div *ngIf="course && !column" class="course-progress section-divider">
        <h3 class="h3__black-title">{{'app.sidebar.my-progress-heading' | translate}}</h3>
        <progress-bar [progress]="getProgressOfCourse(course)"></progress-bar>
      </div>
    </div>

    <div class="links">
      <ng-container
        *ngIf="(loginService.isLoggedIn | async) && !(loginService.isAnonymous | async)"
      >
        <app-language-toggle *ngIf="isI18nEnabled && !course" class="language-toggle"
                             [label]="'app.common.select-language' | translate"
        ></app-language-toggle>

        <button
          class="logout"
          [attr.data-test-id]="TEST_IDS.LOGOUT_BTN"
          (click)="logout()"
        >
          <i class="fas fa-sign-out"></i>
          {{ 'app.user.logout' | translate}}
        </button>

        <button
          class="admin"
          routerLink="/admin/dashboard"
          *ngIf="loginService.isAdmin | async"
        >
          {{ 'app.user.admin' | translate}}
          <i class="fas fa-lock"></i>
        </button>
      </ng-container>
      <ng-container *ngIf="(loginService.isAnonymous | async)">
        <div class="login" (click)="login()">Login</div>
        <div class="register btn primary" (click)="register()">
          {{ 'app.user.register' | translate}}
        </div>
      </ng-container>
    </div>
  </div>
</div>

<app-dialog
  #buyModal
  [id]="modalService.MODAL_IDS.BUY_COURSE"
  [title]="'app.sidebar.buy-modal-title' | translate"
  [submitText]="'app.common.buy-course-button' | translate"
  [cancelText]="((loginService.isAnonymous | async) ? 'app.sidebar.login-button' : 'app.common.cancel') | translate"
  [closable]="true"
  (onSubmit)="orderCourse()"
  (onCancel)="buyCourseModalOnCancel()"
>
  <p>
    {{'app.sidebar.buy-modal-text' | translate}}
  </p>
</app-dialog>

<app-dialog
  #registerModal
  [id]="modalService.MODAL_IDS.REGISTER"
  [cancelable]="true"
  [title]="'app.user.please-register' | translate"
  [submitText]="'app.user.register' | translate"
  [cancelText]="'app.user.login' | translate"
  [closable]="true"
  (onSubmit)="register()"
  (onCancel)="login()"
  [attr.data-test-id]="TEST_IDS.REGISTER_MODAL"
>
  <p>
    {{'app.sidebar.register-modal-text' | translate}}
  </p>
</app-dialog>

<app-dialog
  #learningOverviewModal
  [id]="modalService.MODAL_IDS.LEARNING_OVERVIEW"
  [cancelable]="false"
  [closable]="true"
  [title]="'app.sidebar.no-progress-modal-title' | translate"
  (onClose)="modalService.close(learningOverviewModal)"
  (onSubmit)="modalService.close(learningOverviewModal)"
  [submitText]="'app.common.understood-button' | translate"
>
  <p>
    {{'app.sidebar.no-progress-modal-text' | translate}}
  </p>
</app-dialog>
