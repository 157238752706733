import { Injectable } from '@angular/core';
import IProfile from '@models/profile';
import { ICronJob, SortDirectionKeys, SortKeyType } from '@models/cronjob';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '@core/http';
import { createProfileUrl } from '@utils/urlFactory';
import { IListResponse, ISelfReactivation, SelfReactivationSortKeyType } from '@models/self-reactivation';

const sortDirectionMap = {
  asc: 1,
  desc: -1,
};

const buildSortParams = (sortObject) => {
  return !sortObject ? '' : Object.keys(sortObject)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${
            sortDirectionMap[encodeURIComponent(sortObject[key])]
          }`,
      )
      .join(',');
};

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public profile = new BehaviorSubject<IProfile>(null);

  constructor(private client: HttpService<IProfile>) {}

  public me(): BehaviorSubject<IProfile> {
    const url = createProfileUrl('me');
    this.client.get<IProfile>(url).subscribe((profile) => {
      this.profile?.next(profile);
    });
    return this.profile;
  }

  public setProfile(profile: IProfile) {
    this.profile.next(profile);
  }

  public getReactivationAccessList(
    sortObject: SortDirectionKeys<SelfReactivationSortKeyType>,
    approvalStatus: string | undefined,
    page: number,
    limit: number,
  ): Observable<IListResponse<ISelfReactivation>> {
    const querySortParams = buildSortParams(sortObject);

    const urlParams = [
      'reactivation',
      'list',
      approvalStatus,
      `?sortParams=${querySortParams}&page=${page}&limit=${limit}`,
    ];
    const urlParamsFiltered = urlParams.filter(Boolean);

    const url = createProfileUrl(...urlParamsFiltered);
    return this.client.get<IListResponse<ISelfReactivation>>(url);
  }

  public getCronJobList(
    succeded: string = null,
    page: number,
    limit: number,
    sortObject: SortDirectionKeys<SortKeyType>,
  ): Observable<ICronJob[]> {
    const querySortParams = buildSortParams(sortObject);

    const urlParams = [
      'cronjob',
      'list',
      `?sortParams=${querySortParams}&page=${page}&limit=${limit}&succeded=${succeded}`,
    ];
    const urlParamsFiltered = urlParams.filter(Boolean);

    const url = createProfileUrl(...urlParamsFiltered);
    return this.client.get<ICronJob[]>(url);
  }

  public reactivationAccessApprove(
    reactivationId: string,
  ): Observable<ISelfReactivation> {
    const url = createProfileUrl('reactivation', 'approve', reactivationId);
    return this.client.post<ISelfReactivation>(url, {});
  }

  public removeSelfReactivationRequest(
    reactivationId: string,
  ): Observable<ISelfReactivation> {
    const url = createProfileUrl('reactivation', reactivationId);
    return this.client.delete<ISelfReactivation>(url);
  }

  public reactivationAccessReject(
    reactivationId: string,
    body,
  ): Observable<ISelfReactivation> {
    const url = createProfileUrl('reactivation', 'reject', reactivationId);
    return this.client.post<ISelfReactivation>(url, body);
  }

  public setLanguage(code: string) {
    return this.client.put<IProfile>(createProfileUrl('language', code), {});
  }
}
